@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.ourExpertise {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 117px;

  .ourExpertiseTitle {
    width: 100%;
    max-width: 490px;
    padding: 0 20px;
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 90px;
    text-align: center;
    color: #000000;
    margin-bottom: 35px;
  }

  .ourExpertiseSubtitle {
    width: 100%;
    max-width: 1086px;
    padding: 0 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    margin-bottom: 50px;
  }

  .seeMoreButton {
    background: #151515;
    border: 1px solid #151515;
    border-radius: 100px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    padding: 20px 50px;
    transition: 300ms;
    margin: 0 20px;
  }

  .seeMoreButton:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 1024px) {
  .ourExpertise {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    .ourExpertiseTitle {
      max-width: 490px;
      padding: 0 20px;
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #000000;
      margin-bottom: 16px;
    }

    .ourExpertiseSubtitle {
      width: 100%;
      max-width: 1086px;
      padding: 0 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin-bottom: 20px;
    }

    .seeMoreButton {
      background: #151515;
      border: 1px solid #151515;
      border-radius: 100px;
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      padding: 20px 50px;
      transition: 300ms;
      margin: 0 20px;
    }

    .seeMoreButton:hover {
      opacity: 0.8;
    }
  }
}
