@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.contactUsPage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 90px;
  padding-top: 155px;

  .contactUsContainer {
    width: 100%;
    max-width: 1225px;
    padding: 65px 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .contactUsTextContent {
      width: 100%;
      max-width: 543px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .contactUsTextContentTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 55px;
        line-height: 97px;
        color: #ffffff;
        margin-bottom: 29px;
        text-align: start;
      }

      .contactUsTextContentSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-size: 20px;
        line-height: 36px;
        color: #ffffff;
        text-align: start;
      }
    }

    .contactUsTextContent .contactUsTextContentSubtitle:nth-last-child(1) {
      margin-top: 40px;
    }

    .contactUsFormContainer {
      width: 100%;
      max-width: 642px;
      padding-left: 100px;
    }
  }

  .textContentBackground {
    width: 49%;
    height: 677px;
    background: #2730ae;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .contactUsFormBackground {
    width: 80%;
    background: #dee4f9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
  }
}

.contactUs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 90px;

  .contactUsContainer {
    width: 100%;
    max-width: 1225px;
    padding: 65px 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .contactUsTextContent {
      width: 100%;
      max-width: 543px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .contactUsTextContentTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 900;
        font-size: 55px;
        line-height: 82px;
        color: #ffffff;
        margin-bottom: 29px;
        text-align: start;
      }

      .contactUsTextContentSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-size: 20px;
        line-height: 36px;
        color: #ffffff;
        text-align: start;
      }
    }

    .contactUsTextContent .contactUsTextContentSubtitle:nth-last-child(1) {
      margin-top: 40px;
    }

    .contactUsFormContainer {
      width: 100%;
      max-width: 642px;
      padding-left: 100px;
    }
  }

  .textContentBackground {
    width: 49%;
    height: 450px;
    background: #2730ae;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .contactUsFormBackground {
    width: 80%;
    background: #dee4f9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
  }
}

@media screen and (max-width: 1024px) {
  .contactUsPage {
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;

    .contactUsContainer {
      max-width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contactUsTextContent {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #2730ae;
        padding: 115px 20px 40px 20px;

        .contactUsTextContentTitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 54px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 20px;
        }

        .contactUsTextContentSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
        }
      }

      .contactUsTextContent .contactUsTextContentSubtitle:nth-last-child(1) {
        margin-top: 0;
      }

      .contactUsFormContainer {
        max-width: 100%;
        padding-left: 0;
      }
    }

    .textContentBackground {
      display: none;
    }

    .contactUsFormBackground {
      display: none;
    }
  }

  .contactUs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 26px;

    .contactUsContainer {
      width: 100%;
      max-width: 100%;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contactUsTextContent {
        width: 100%;
        max-width: 100%;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #2730ae;

        .contactUsTextContentTitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 54px;
          color: #ffffff;
          margin-bottom: 20px;
          text-align: center;
        }

        .contactUsTextContentSubtitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          margin-top: 0;
        }
      }

      .contactUsTextContent .contactUsTextContentSubtitle:nth-last-child(1) {
        margin-top: 0;
      }

      .contactUsFormContainer {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
      }
    }

    .textContentBackground {
      display: none;
    }

    .contactUsFormBackground {
      display: none;
    }
  }
}
