@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.whyStatecs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2730ae;
  margin-top: 60px;

  .whyStatecsContainer {
    width: 100%;
    max-width: 1560px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;

    .title {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 900;
      font-size: 70px;
      line-height: 105px;
      color: #ffffff;
      margin-bottom: 10px;
    }

    .subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
      max-width: 900px;

      .bold {
        font-family: "PoppinsBold";
        font-weight: 600;
      }
    }

    .whyStatecsCards {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 60px;
    }

    .whyStatecsCards > .whyStatecsCard:nth-last-child(1) {
      margin-right: 0;
    }
  }

  .whyStatecsContainer > span:nth-child(2) {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .whyStatecs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2730ae;
    margin-top: 40px;

    .whyStatecsContainer {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 20px;

      .title {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        color: #ffffff;
        margin-bottom: 10px;
      }

      .subtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        max-width: 900px;

        .bold {
          font-family: "PoppinsBold";
          font-weight: 600;
        }
      }

      .whyStatecsCards {
        display: none;
      }
    }

    .whyStatecsContainer > span:nth-child(2) {
      margin-bottom: 32px;
    }
  }
}
