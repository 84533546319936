.CarouselSectionContainer {
  width: 100%;
  margin-top: 46px;

  .carousel {
    width: 100%;
    display: none;

    .ant-carousel .slick-dots-bottom {
      display: none !important;
    }

    .ant-carousel {
      width: 100%;
    }

    .ant-carousel {
      width: 100%;

      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgb(0 0 0 / 85%);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;

      font-feature-settings: "tnum", "tnum";
      .carousel-title {
        color: white;

        font-feature-settings: "tnum", "tnum";
      }

      .carousel-title-black {
        color: white;
      }

      .carousel-content {
        width: 60%;
        color: white;
        height: auto;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .carousel-content-black {
        width: 60%;
        color: black;
        height: auto;
        line-height: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .CarouselSectionContainer {
    .carousel {
      display: flex;
    }
  }
}

@media screen and (max-width: 900px) {
  .CarouselSectionContainer {
    .carousel {
      .carousel-content {
        width: 70%;
      }

      .carousel-content-black {
        width: 70%;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .CarouselSectionContainer {
    .carousel {
      .carousel-title {
        font-size: 19px;
      }

      .carousel-title-black {
        font-size: 19px;
      }

      .carousel-content {
        width: 75%;
        font-size: 13px;
      }

      .carousel-content-black {
        width: 75%;
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .carousel {
    .carousel-title {
      font-size: 18px;
    }

    .carousel-title-black {
      font-size: 18px;
    }

    .carousel-content {
      width: 85%;
      font-size: 12px;
    }

    .carousel-content-black {
      width: 85%;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}

@media screen and (max-width: 400px) {
  .carousel {
    .carousel-title {
      font-size: 15px;
    }

    .carousel-title-black {
      font-size: 15px;
    }

    .carousel-content {
      width: 95%;
      font-size: 11px;
      margin-bottom: 5px;
    }

    .carousel-content-black {
      width: 95%;
      font-size: 11px;
      margin-bottom: 0;
    }
  }
}
