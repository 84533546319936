.homePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 254px;
  background-image: url(../../assets/images/home-page-background.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  overflow: hidden;
}

@media screen and (max-width: 1400px) {
  .homePage {
    background-size: auto 1200px;
  }
}

@media screen and (max-width: 1024px) {
  .homePage {
    background-size: auto 800px;
  }
}

@media screen and (max-width: 800px) {
  .homePage {
    background-size: auto 600px;
  }
}
