@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.aboutUsPageHeader {
  width: 100%;
  height: 884px;
  background-image: url(../../../../assets/images/about-us-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .aboutUsPageHeaderContainer {
    width: 100%;
    max-width: 1560px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;

    .headerTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 900;
      font-size: 85.2427px;
      line-height: 80px;
      color: #ffffff;
      margin-bottom: 30px;
      margin-top: 350px;
      text-align: start;
    }

    .headerSubtitle {
      width: 100%;
      max-width: 726px;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      text-align: start;
      color: #ffffff;
      text-align: start;
      text-shadow: 2px 2px #000;
    }
  }

  .aboutUsHeaderContent {
    position: absolute;
    top: -60px;
    bottom: -60px;
    left: -60px;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(14, 30, 84, 0.81) 19.72%,
      rgba(38, 69, 180, 0) 97.69%
    );
    mix-blend-mode: normal;
    filter: blur(19px);
  }
}

@media screen and (max-width: 1024px) {
  .aboutUsPageHeader {
    width: 100%;
    height: 476px;
    background-image: url(../../../../assets/images/about-us-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;

    .aboutUsPageHeaderContainer {
      width: 100%;
      max-width: 100%;
      padding: 0 20px 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .headerTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
        margin-top: 350px;
        text-align: center;
        margin-bottom: 10px;
      }

      .headerSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        color: #ffffff;
        text-align: center;
      }
    }

    .aboutUsHeaderContent {
      position: absolute;
      top: -350px;
      bottom: -300px;
      left: -120px;
      right: -120px;
      background: linear-gradient(
        90deg,
        #0b1539 24%,
        rgba(38, 69, 180, 0) 97.69%
      );
      mix-blend-mode: normal;
      filter: blur(19px);
      transform: rotate(-90deg);
    }
  }
}
