@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

.trainingsSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 57px;
  margin-bottom: 52px;
  background: #f6f8ff;

  .trainingsSectionTitle {
    width: 100%;
    max-width: 946px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #151515;
    margin-bottom: 60px;
  }

  .trainingsSectionCards {
    width: 100%;
    max-width: 1540px;
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .trainingsSectionCards > .trainingsCard:nth-last-child(1) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .trainingsSection {
    padding: 20px;
    margin-bottom: 0;

    .trainingsSectionTitle {
      max-width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #151515;
      text-align: start;
      margin-bottom: 20px;
    }

    .trainingsSectionCards {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
}
