@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../assets/fonts/Poppins-Bold.ttf);
}

.dataManagementTraining {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .ourTrainingPrograms {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 87px;

    .ourTrainingProgramsBackground {
      background-image: url(../../../assets/images/our-training-background.png);
      background-size: cover;
      background-position: right center;
      position: absolute;
      top: 0;
      left: 20%;
      right: 0;
      bottom: 0;
    }

    .ourTrainingProgramsBlurBackground {
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      bottom: -40px;
      background: linear-gradient(
        90deg,
        #fdfdfd 40.24%,
        rgba(253, 253, 253, 0) 100%
      );
      z-index: 1;
    }

    .ourTrainingProgramsContent {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      .ourTrainingProgramsContentContainer {
        width: 100%;
        max-width: 1560px;
        padding: 82px 20px 86px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .ourTrainingProgramsContentTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 54px;
          color: #151515;
          margin-bottom: 25px;
          text-align: start;
        }

        .ourTrainingProgramsContentSubtitle {
          max-width: 589px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: #151515;
          text-align: start;
          margin-bottom: 31px;
        }

        .trainingApplyButton {
          padding: 20px 50px;
          background: #151515;
          border: 1px solid #151515;
          border-radius: 100px;
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #ffffff;
          transition: 300ms;
        }

        .trainingApplyButton:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .afterSASTrainingSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 96px 20px 20px 20px;
    background: #2730ae;
    margin-bottom: 70px;

    .afterSASTrainingSectionSubtitle {
      max-width: 900px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 60px;
    }

    .afterSASTrainingSectionApplyButton {
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 100px;
      padding: 20px 50px;
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #151515;
      margin-bottom: 60px;
      transition: 300ms;
    }

    .afterSASTrainingSectionApplyButton:hover {
      opacity: 0.8;
    }

    .afterSASTrainingSectionCardsTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
      margin-bottom: 40px;
    }

    .afterTrainingsCards {
      width: 100%;
      max-width: 1560px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }

  .afterdataManagementTrainingHeader {
    width: 100%;
    max-width: 906px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;

    .afterdataManagementTrainingHeaderTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      color: #000000;
      margin-bottom: 30px;
    }

    .afterdataManagementTrainingHeaderSubtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #151515;
    }
  }

  .whyChooseStatecsTrainings {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 87px;

    .whyChooseStatecsTrainingsBackground {
      background-image: url(../../../assets/images/why-choose-background.png);
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .whyChooseStatecsTrainingsContent {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      z-index: 2;
      padding: 95px 0 115px 0;

      .whyChooseStatecsTrainingsContentContainer {
        width: 100%;
        max-width: 854px;
        padding: 42px 0 30px 97px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background: rgba(255, 255, 255, 0.8);

        .whyChooseStatecsTrainingsContentTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 54px;
          color: #151515;
          margin-bottom: 25px;
          text-align: start;
        }

        .whyChooseStatecsTrainingsContentSubtitle {
          max-width: 534px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: #151515;
          text-align: start;
          margin-bottom: 31px;
        }

        .trainingApplyButton {
          padding: 20px 50px;
          background: #151515;
          border: 1px solid #151515;
          border-radius: 100px;
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #ffffff;
          transition: 300ms;
        }

        .trainingApplyButton:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dataManagementTraining {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .ourTrainingPrograms {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      margin-bottom: 0;

      .ourTrainingProgramsBackground {
        width: 100%;
        height: 500px;
        background-image: url(../../../assets/images/our-training-background.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 80% center;
        position: relative;
        left: 0;
      }

      .ourTrainingProgramsBlurBackground {
        display: none;
      }

      .ourTrainingProgramsContent {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;

        .ourTrainingProgramsContentContainer {
          width: 100%;
          max-width: 1560px;
          padding: 16px 20px 60px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .ourTrainingProgramsContentTitle {
            font-family: "PoppinsBold";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #151515;
            margin-bottom: 8px;
            text-align: center;
          }

          .ourTrainingProgramsContentSubtitle {
            max-width: 100%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #151515;
            margin-bottom: 30px;
          }

          .trainingApplyButton {
            padding: 20px 50px;
            background: #151515;
            border: 1px solid #151515;
            border-radius: 100px;
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: #ffffff;
          }
        }
      }
    }

    .afterSASTrainingSection {
      padding: 40px 20px 30px 20px;
      margin-bottom: 30px;

      .afterSASTrainingSectionSubtitle {
        max-width: 900px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
      }

      .afterSASTrainingSectionCardsTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
        margin-bottom: 20px;
      }

      .afterTrainingsCards {
        display: none;
      }
    }

    .afterdataManagementTrainingHeader {
      max-width: 100%;
      margin-bottom: 30px;

      .afterdataManagementTrainingHeaderTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #000000;
        margin-bottom: 12px;
      }

      .afterdataManagementTrainingHeaderSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #151515;
      }
    }

    .whyChooseStatecsTrainings {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      margin-bottom: 60px;

      .whyChooseStatecsTrainingsBackground {
        background-image: url(../../../assets/images/why-choose-background.png);
        background-size: auto 353px;
        background-repeat: no-repeat;
        background-position: top center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      .whyChooseStatecsTrainingsContent {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        padding: 231px 0 0 0;

        .whyChooseStatecsTrainingsContentContainer {
          max-width: 100%;
          padding: 20px;
          margin: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: rgba(255, 255, 255, 0.8);

          .whyChooseStatecsTrainingsContentTitle {
            font-family: "PoppinsBold";
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 54px;
            color: #151515;
            margin-bottom: 25px;
            text-align: start;
          }

          .whyChooseStatecsTrainingsContentSubtitle {
            max-width: 534px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #151515;
            text-align: start;
            margin-bottom: 31px;
          }

          .trainingApplyButton {
            padding: 20px 50px;
            background: #151515;
            border: 1px solid #151515;
            border-radius: 100px;
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
