@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

.serviceCard {
  width: 100%;
  padding: 15px 0px 15px 40px;
  background: #dee4f9;
  border-left: 10px solid #6a84f1;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  .serviceCardContent {
    width: 100%;
    max-width: 808px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #151515;
    text-align: start;
  }
}

@media screen and (max-width: 1024px) {
  .serviceCard {
    padding: 15px 45px 15px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    .serviceCardContent {
      max-width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #151515;
      text-align: start;
    }
  }
}
