@font-face {
  font-family: "Poppins";
  src: url(../../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../../assets/fonts/Poppins-Bold.ttf);
}

.ourServicesCard {
  width: 100%;
  max-width: 403px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #2730ae;
  height: 400px;
  transition: 2000ms;

  .icon {
    margin: 20px;
    width: auto;
    height: 60px;
  }

  .title {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: start;
  }

  .subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: #ffffff;
  }
}

.ourServicesCard:hover {
  box-shadow: inset 0 850px 0 0 #151515;
  color: white;
}

@media screen and (max-width: 1480px) {
  .ourServicesCard {
    height: 450px;
  }
}

@media screen and (max-width: 1300px) {
  .ourServicesCard {
    height: 500px;
  }
}

@media screen and (max-width: 1150px) {
  .ourServicesCard {
    height: 550px;
  }
}

@media screen and (max-width: 1024px) {
  .ourServicesCard {
    width: 100%;
    max-width: 100%;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #2730ae;
    transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  .ourServicesCard:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 700px) {
  .ourServicesCard {
    height: 350px;
  }
}

@media screen and (max-width: 500px) {
  .ourServicesCard {
    height: 440px;
  }
}

@media screen and (max-width: 400px) {
  .ourServicesCard {
    height: 500px;
  }
}
