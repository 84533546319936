@font-face {
  font-family: "Poppins";
  src: url(../../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../../assets/fonts/Poppins-Bold.ttf);
}

.collapse-container {
  width: 100%;

  .ant-collapse-item-active {
    .ant-collapse-header {
      background: #151515 !important;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    transition: all 1500ms, visibility 1500ms;
  }

  .collapse {
    width: 100%;
    background-color: transparent;

    .panel {
      margin-bottom: 20px;
    }

    .panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0;

      .icon-container {
        width: 240px;
        height: 120px;
        margin-left: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }

      .icon {
        max-height: 120px;
      }

      .panel-header-title {
        width: 100%;
        max-width: 405px;
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 44px;
        color: #ffffff;
        text-align: start;
        margin-left: 20px;
        word-break: break-word !important;
      }

      .panel-header-subtitle {
        width: 100%;
        max-width: 488px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        text-align: start;
        margin-left: 20px;
        margin-right: 120px;
      }

      .arrowIcon {
        display: none;
      }
    }

    .ant-collapse-header {
      background: #2730ae;
      transition: 3s;
    }

    .ant-collapse-header:hover {
      box-shadow: inset 0 250px 0 0 #151515;
      color: white;
    }

    .ant-collapse-content-box {
      background: #dee4f9;
    }
  }
}

@media screen and (max-width: 1024px) {
  .collapse-container {
    .collapse {
      .panel-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 18px 0;

        .icon-container {
          width: 50px;
          height: 50px;
          margin-left: 0;
          margin-bottom: 16px;

          .icon {
            height: 100%;
            width: auto;
          }
        }

        .panel-header-title {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          text-align: center;
          margin-left: 0;
          margin-bottom: 8px;
        }

        .panel-header-subtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          text-align: center;
          margin-left: 0;
          margin-right: 0;
        }

        .arrowIcon {
          display: block;
        }
      }

      .ant-collapse-header {
        background: #2730ae;
        padding: 20px !important;
        transition: 2000ms;

        .ant-collapse-expand-icon {
          display: none;
        }
      }

      .ant-collapse-header:hover {
        box-shadow: inset 0 400px 0 0 #151515;
        color: white;
      }

      .ant-collapse-content-box {
        background: #dee4f9;
      }

      .ant-collapse-content-box > div {
        padding: 20px 0;
      }
    }
  }
}
