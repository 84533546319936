@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../assets/fonts/Poppins-Bold.ttf);
}


.trainingInfo {
  width: 100%;
  max-width: 1110px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #ffffff;
  margin-bottom: 50px;
  background: rgba(0, 0, 0, 0.4);
  height: 262px;

  .trainingInfoDate {
    padding: 30px 26px 16px 26px;
    height: 100%;
    background: #dee4f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .trainingInfoDateCalendar {
      width: 56px;
      height: 63px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .trainingInfoDateCalendarMonth {
        width: 100%;
        background: #2431d8;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff;
      }

      .trainingInfoDateCalendarDay {
        width: 100%;
        height: 47px;
        background-color: #ffffff;
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 100%;
        color: #2431d8;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .trainingInfoDateCalendarWeekDay {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #000000;
      }
    }
  }

  .trainingEventDetails {
    height: 100%;
    padding: 20px 16px 32px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid #ffffff;

    .trainingEventDetailsTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: #ffffff;
      text-align: start;
      margin-bottom: 17px;
    }

    .trainingEventDetailsSubtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      text-align: start;
      margin-bottom: 5px;
    }

    .trainingEventDetailsSubtitleContent {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      text-align: start;
      margin-bottom: 26px;
    }
  }

  .trainingEventDetails
    > .trainingEventDetailsSubtitleContent:nth-last-child(1) {
    margin-bottom: 0;
  }

  .trainingComingSoon {
    padding: 20px 40px 50px 40px;
    height: 100%;
    border-right: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .trainingComingSoonTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      text-align: center;
      color: #ffffff;
    }

    .trainingComingSoonTimer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .trainingComingSoonTimerCard {
        padding: 20px 10px 20px 8px;
        border: 1px solid #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        .timerCardNumbers {
          width: 86px;
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 70px;
          line-height: 85%;
          letter-spacing: -0.08em;
          text-transform: uppercase;
          color: #ffffff;
          text-align: center;
          margin-bottom: 5px;
        }

        .timerCardText {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #ffffff;
        }
      }
    }
  }

  .trainingComingSoonTimer > .trainingComingSoonTimerCard:nth-last-child(1) {
    margin-right: 0;
  }

  .trainingAgenda {
    width: 220px;
    padding: 20px 40px 103px 55px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    .trainingAgendaTitle {
      width: 130px;
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 17px;
      text-align: start;
    }

    .trainingAgendaContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .trainingAgendaContentSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .trainingAgendaSubtitle {
          width: 130px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 5px;
          text-align: start;
        }

        .trainingAgendaSubtitleContent {
          width: 130px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 28px;
          text-align: start;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .trainingInfo {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffffff;
    margin-bottom: 21px;
    background: rgba(0, 0, 0, 0.4);
    height: auto;
    position: relative;

    .trainingInfoDate {
      padding: 8px 10px;
      width: 100%;
      height: auto;
      background: #dee4f9;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ffffff;

      .trainingInfoDateCalendar {
        width: 56px;
        height: 63px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .trainingEventDetails {
      width: 335px;
      height: 100%;
      padding: 17px 22px 28px 22px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-right: none;
      border-bottom: 1px solid #ffffff;
    }

    .trainingComingSoon {
      padding: 20px 0 50px 0;
      height: 100%;
      border-right: none;
      border-bottom: 1px solid #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .trainingComingSoonTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        text-align: center;
        color: #ffffff;
      }

      .trainingComingSoonTimer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .trainingComingSoonTimerCard {
          padding: 16px 8px 16px 6px;
          border: 1px solid #ffffff;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          margin-top: 16px;

          .timerCardNumbers {
            width: 86px;
            font-family: "PoppinsBold";
            font-style: normal;
            font-weight: 700;
            font-size: 59.2458px;
            line-height: 85%;
            letter-spacing: -0.08em;
            text-transform: uppercase;
            color: #ffffff;
            text-align: center;
            margin-bottom: 5px;
          }

          .timerCardText {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
          }
        }
      }
    }

    .trainingComingSoonTimer > .trainingComingSoonTimerCard:nth-last-child(1) {
      margin-right: 0;
    }

    .trainingAgenda {
      width: 100%;
      max-width: 335px;
      padding: 20px 22px 103px 22px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .trainingAgendaTitle {
        width: 130px;
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        color: #ffffff;
        margin-bottom: 17px;
        text-align: start;
      }

      .trainingAgendaContent {
        flex-direction: row;
        justify-content: space-between;

        .trainingAgendaContentSection {
          margin-right: 35px;

          .trainingAgendaSubtitle {
            width: 130px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
            margin-bottom: 5px;
            text-align: start;
          }

          .trainingAgendaSubtitleContent {
            width: 130px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
            margin-bottom: 28px;
            text-align: start;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .trainingInfo {
    .trainingEventDetails {
      width: 300px;
      height: 100%;
      padding: 17px 22px 28px 22px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-right: none;
      border-bottom: 1px solid #ffffff;
    }

    .trainingComingSoon {
      .trainingComingSoonTimer {
        .trainingComingSoonTimerCard {
          padding: 16px 6px 16px 4px;
          border: 1px solid #ffffff;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          margin-top: 16px;

          .timerCardNumbers {
            width: 75px;
            font-family: "PoppinsBold";
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 75%;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #ffffff;
            text-align: center;
            margin-bottom: 5px;
          }

          .timerCardText {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
          }
        }
      }
    }

    .trainingComingSoonTimer > .trainingComingSoonTimerCard:nth-last-child(1) {
      margin-right: 0;
    }

    .trainingAgenda {
      width: 100%;
      max-width: 335px;
      padding: 20px 22px 103px 22px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .trainingAgendaTitle {
        width: 130px;
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        color: #ffffff;
        margin-bottom: 17px;
        text-align: start;
      }

      .trainingAgendaContent {
        flex-direction: row;
        justify-content: space-between;

        .trainingAgendaContentSection {
          margin-right: 35px;

          .trainingAgendaSubtitle {
            width: 130px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
            margin-bottom: 5px;
            text-align: start;
          }

          .trainingAgendaSubtitleContent {
            width: 130px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
            margin-bottom: 28px;
            text-align: start;
          }
        }
      }
    }
  }
}
