@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.OurVision {
  width: 100%;
  max-width: 1560px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 90px;

  .OurVisionTextContent {
    width: 100%;
    max-width: 710px;
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .OurVisionTextContentTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 94%;
      color: #151515;
      padding-top: 167px;
      margin-bottom: 30px;
      text-align: start;
    }

    .OurVisionTextContentSubtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #151515;
      text-align: start;
    }
  }

  .OurVisionCover {
    width: 100%;
    max-width: 750px;
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .OurVision {
    max-width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    .OurVisionTextContent {
      max-width: 100%;
      margin-left: 0;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .OurVisionTextContentTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #151515;
        padding-top: 16px;
        margin-bottom: 8px;
        text-align: center;
      }

      .OurVisionTextContentSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #151515;
      }
    }

    .OurVisionCover {
      max-width: 100%;
      height: auto;
    }
  }
}
