@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../assets/fonts/Poppins-Bold.ttf);
}

.afterSASTraining {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 57px;
  margin-bottom: 90px;

  .afterSASTrainingHeader {
    width: 100%;
    max-width: 906px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;

    .afterSASTrainingHeaderTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #151515;
    }
  }

  .afterSASTrainingSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px 20px 20px;
    background: #2730ae;

    .afterSASTrainingSectionTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
      margin-bottom: 20px;
    }

    .afterSASTrainingSectionSubtitle {
      max-width: 728px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
    }

    .afterSASTrainingSectionApplyButton {
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 100px;
      padding: 20px 50px;
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #151515;
      margin-bottom: 60px;
      transition: 300ms;
      margin-top: 40px;
    }

    .afterSASTrainingSectionApplyButton:hover {
      opacity: 0.8;
    }

    .afterSASTrainingSectionCardsTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
      margin-bottom: 40px;
    }

    .afterTrainingsCards {
      width: 100%;
      max-width: 1560px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .afterSASTraining {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 60px;

    .afterSASTrainingHeader {
      max-width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 30px;

      .afterSASTrainingHeaderTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #151515;
        text-align: start;
      }
    }

    .afterSASTrainingSection {
      padding: 20px;

      .afterSASTrainingSectionTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 20px;
      }

      .afterSASTrainingSectionSubtitle {
        max-width: 100%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
      }

      .afterSASTrainingSectionApplyButton {
        margin-bottom: 40px;
      }

      .afterSASTrainingSectionCardsTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 20px;
      }

      .afterTrainingsCards {
        display: none;
      }
    }
  }
}
