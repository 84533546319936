@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../assets/fonts/Poppins-Bold.ttf);
}

.statecsAdvantageCard {
  width: 100%;
  height: 320px;
  padding: 36px;
  background-color: #ffffff;
  border: 1px solid #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  margin-right: 20px;

  .statecsAdvantageCardHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      width: auto;
      height: 57px;
      margin-bottom: 30px;
    }

    .title {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #151515;
      margin-bottom: 28px;
    }
  }

  .singleIcon {
    width: auto;
    height: 57px;
    margin-bottom: 17px;
  }

  .subtitle {
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #151515;
    margin-bottom: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .statecsAdvantageCard {
    padding: 25px 35px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #f9f9f9;
    display: flex;
    margin-right: 0;
    margin-bottom: 8px;

    .statecsAdvantageCardHeader {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      .icon {
        width: auto;
        height: 57px;
        margin-bottom: 17px;
      }

      .title {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #151515;
        margin-bottom: 28px;
        margin-left: 25px;
      }
    }

    .singleIcon {
      width: auto;
      height: 57px;
      margin-bottom: 17px;
    }

    .subtitle {
      width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-align: start;
      color: #151515;
      margin-bottom: 28px;
    }
  }
}
