@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.expertiseHeader {
  width: 100%;
  height: 564px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: url(../../../../assets/images/expertise-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  .expertiseHeaderBackground {
    position: absolute;
    background: linear-gradient(
      90deg,
      #162b75 -7.53%,
      rgba(38, 69, 180, 0) 97.69%
    );
    mix-blend-mode: normal;
    filter: blur(9.5px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .expertiseHeaderContainer {
    z-index: 1;
    width: 100%;
    max-width: 1560px;
    padding: 0 20px 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .expertiseHeaderTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 900;
      font-size: 72px;
      line-height: 97px;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .expertiseHeaderSubtitle {
      max-width: 800px;
      font-style: normal;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      text-align: start;
      color: #ffffff;
      text-align: start;
      text-shadow: 2px 2px #000;
    }
  }
}

@media screen and (max-width: 1024px) {
  .expertiseHeader {
    width: 100%;
    height: 375px;

    .expertiseHeaderContainer {
      width: 100%;
      max-width: 100%;
      padding: 0 20px 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .expertiseHeaderTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        text-align: center;
        color: #ffffff;
        margin-bottom: 16px;
      }

      .expertiseHeaderSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
