@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

.afterTrainingsCard {
  width: 20%;
  height: 265px;
  margin-right: 16px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 300ms;

  .afterTrainingsCardIcon {
    margin-bottom: 18px;
  }

  .afterTrainingsCardContent {
    max-width: 220px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    text-align: center;
  }
}

.afterTrainingsCard:hover {
  box-shadow: (-8px 7px 40px rgba(6, 10, 23, 0.6));
}

@media screen and (max-width: 1024px) {
  .afterTrainingsCard {
    width: 100%;
  }
}
