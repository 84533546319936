@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.regulatoryAffairsContent {
  width: 100%;
  max-width: 1086px;
  padding: 50px 20px 56px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .regulatoryAffairsTextContent {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #151515;
    margin-bottom: 40px;
    text-align: start;
  }

  .regulatoryAffairsContentSubtitle {
    width: 100%;
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #151515;
    margin-bottom: 30px;
    text-align: start;
  }

  .regulatoryAffairsCards {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .regulatoryAffairsContent {
    max-width: 100%;
    padding: 20px 20px 35px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .regulatoryAffairsTextContent {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #151515;
      margin-bottom: 30px;
      text-align: start;
    }

    .regulatoryAffairsContentSubtitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-transform: uppercase;
      color: #151515;
      margin-bottom: 10px;
      text-align: start;
    }

    .regulatoryAffairsCards {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
