@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.appFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .appFooterContainer {
    width: 100%;
    max-width: 1280px;
    padding: 60px 20px 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .footerNavigation {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .footerCollapse {
        display: none;
      }

      .footerNavigationSections,
      .footerNavigationSectionsContact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #2e3544;
          margin-bottom: 40px;
        }

        .item {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #2e3544;
          text-align: start;
          margin-bottom: 30px;
          cursor: pointer;
        }

        .prefixItem {
          width: 100%;
          max-width: 220px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 24px;

          .contactItem {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #2e3544;
            margin-left: 12px;
            text-align: start;
            margin-bottom: 0;
            cursor: pointer;
          }
        }
      }

      .footerNavigationSections,
      .footerNavigationSectionsContact > .item:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .statecs {
      width: 100%;
      margin-top: 75px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 163px;
        height: auto;
      }

      .rights {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #2e3544;
        opacity: 0.6;
        word-break: keep-all;
      }

      .socialMedia {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .followUs {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #2e3544;
          margin-bottom: 10px;
        }

        .socialMediaIcons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;

          .socialMediaIcoн {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .appFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .appFooterContainer {
      width: 100%;
      max-width: 100%;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .footerNavigation {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .footerCollapse {
          display: block;

          .ant-collapse {
            margin-bottom: 10px;
            background-color: transparent;
            margin-bottom: 40px;
            padding: 0;

            .ant-collapse-borderless {
              background-color: transparent;
              border: 0;
            }

            .ant-collapse-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .ant-collapse-header {
                background: transparent;
                padding: 0 40px 0 16px;
                max-width: 260px;
                margin-right: 0;

                .footerItem {
                  font-family: "PoppinsBold";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 27px;
                  text-align: center;
                  color: #2e3544;
                }
              }

              .ant-collapse-content {
                .ant-collapse-content-box {
                  width: 100%;
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  .link {
                    margin-bottom: 30px;
                    .footerItem {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      color: #2e3544;
                    }
                  }
                }

                .ant-collapse-content-box > .link:nth-child(1) {
                  margin-top: 30px;
                }

                .ant-collapse-content-box > .link:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        .footerNavigationSections {
          display: none;
        }

        .footerNavigationSectionsContact {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .title {
            font-family: "PoppinsBold";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: #2e3544;
            margin-bottom: 40px;
          }

          .item {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #2e3544;
            text-align: start;
            margin-bottom: 30px;
            cursor: pointer;
          }

          .prefixItem {
            width: 100%;
            max-width: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px;

            .contactItem {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #2e3544;
              margin-left: 12px;
              text-align: start;
              margin-bottom: 0;
            }
          }
        }

        .footerNavigationSections > .item:nth-last-child(1) {
          margin-bottom: 0;
        }
      }

      .statecs {
        width: 100%;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo {
          width: 163px;
          height: auto;
          margin-bottom: 30px;
        }

        .rights {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #2e3544;
          opacity: 0.6;
          word-break: keep-all;
        }

        .socialMedia {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .followUs {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #2e3544;
            margin-bottom: 10px;
          }

          .socialMediaIcons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;

            .socialMediaIcoн {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
