@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.programOverview {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 42px;
  background: #f6f8ff;

  .programOverviewBackground {
    width: 100%;
    max-width: 1309px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(../../../../assets/images/program-overview.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .programOverviewContainer {
    width: 100%;
    max-width: 1530px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 120px 20px;
    z-index: 1;

    .programOverviewTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      color: #151515;
      margin-bottom: 45px;
      text-align: start;
    }

    .programOverviewSubtitle {
      max-width: 736px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: start;
      color: #151515;
    }
  }
}

@media screen and (max-width: 1800px) {
  .programOverview {
    .programOverviewContainer {
      .programOverviewSubtitle {
        max-width: 600px;
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .programOverview {
    .programOverviewContainer {
      .programOverviewSubtitle {
        max-width: 500px;
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .programOverview {
    .programOverviewBackground {
      width: 80%;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url(../../../../assets/images/program-overview.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .programOverviewContainer {
      .programOverviewSubtitle {
        max-width: 30%;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .programOverview {
    .programOverviewBackground {
      width: 70%;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url(../../../../assets/images/program-overview.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .programOverviewContainer {
      .programOverviewSubtitle {
        max-width: 30%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .programOverview {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 0;

    .programOverviewBackground {
      width: 100%;
      max-width: 100%;
      height: 300px;
      position: relative;
      background-image: url(../../../../assets/images/program-overview.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
    }

    .programOverviewContainer {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      z-index: 1;

      .programOverviewTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #151515;
        margin-bottom: 8px;
        margin-top: 16px;
        text-align: center;
      }

      .programOverviewSubtitle {
        max-width: 100%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #151515;
      }
    }
  }
}
