@font-face {
  font-family: "Poppins";
  src: url(../../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../../assets/fonts/Poppins-Bold.ttf);
}

.trainingsCard {
  width: 100%;
  max-width: 740px;
  padding: 35px 60px 30px 126px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #2730ae;
  margin-right: 20px;
  transition: 1500ms;

  .trainingIcon {
    width: auto;
    height: 100%;
    max-height: 55px;
    margin-bottom: 30px;
  }

  .trainingsCardTitle {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: start;
  }

  .trainingsCardSubtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-align: start;
    margin-bottom: 20px;
  }

  .seeMoreContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .seeMoreButton {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;

      .seeMoreButtonContent {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
        transition: 300ms;
      }
    }

    .seeMoreButton:hover > .seeMoreButtonContent {
      font-family: "PoppinsBold";
      font-weight: 600;
    }
  }
}

.trainingsCard:hover {
  box-shadow: inset 0 500px 0 0 #151515;
  color: white;
}

@media screen and (max-width: 1345px) {
  .trainingsCard {
    padding: 35px 60px 30px 86px;
  }
}

@media screen and (max-width: 1265px) {
  .trainingsCard {
    padding: 35px 50px 30px 50px;
  }
}

@media screen and (max-width: 1175px) {
  .trainingsCard {
    padding: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .trainingsCard {
    padding: 20px;

    .trainingsCardTitle {
      font-size: 44px;
      line-height: 66px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .trainingsCard {
    max-width: 100%;
    padding: 30px 16px 16px 16px;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-bottom: 8px;

    .trainingIcon {
      width: auto;
      height: 100%;
      max-height: 55px;
      margin-bottom: 30px;
    }

    .trainingsCardTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 8px;
      text-align: center;
    }

    .trainingsCardSubtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 6px;
    }

    .seeMoreContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .seeMoreButton {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: pointer;
      }
    }
  }
}
