@font-face {
  font-family: "Inter";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "InterBold";
  src: url(../../../assets/fonts/Poppins-Bold.ttf);
}

.ant-modal-wrap {
  .successModal {
    width: 625px !important;
    .ant-modal-content {
      .ant-modal-body {
        background: #ffffff;
        border-radius: 25px;
        padding: 57px 20px 52px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .successModalTitle {
          width: 100%;
          max-width: 313px;
          font-family: "InterBold";
          font-style: normal;
          font-weight: 900;
          font-size: 48px;
          line-height: 58px;
          text-align: center;
          color: #151515;
          margin-top: 60px;
          text-align: center;
        }

        .successModalSubtitle {
          width: 100%;
          max-width: 313px;
          margin-top: 30px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          color: #151515;
        }

        .successModalButton {
          width: 100%;
          max-width: 257px;
          margin: 0 20px;
          padding: 20px 50px;
          background: rgba(255, 255, 255, 0.1);
          border: 2px solid #304bb8;
          color: #304bb8;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 58px;
          cursor: pointer;
          transition: 300ms;
        }

        .successModalButton:hover {
          background: #5985ef;
          border: 2px solid #ffffff;
          color: #ffffff;
          border-radius: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
}
