.servicesSection {
  width: 100%;
  max-width: 1680px;
  margin-bottom: 60px;
  margin-top: -139px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .servicesSectionCards {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 2;
  }
}

@media screen and (max-width: 1024px) {
  .servicesSection {
    max-width: 100%;
    padding: 0 20px;
    margin-bottom: 30px;
    margin-top: 10px;
    z-index: 2;

    .servicesSectionCards {
      display: none;
    }
  }
}
