@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.dataManagementTrainingPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f9f9f9;

  .dataManagementPageHeader {
    width: 100%;
    height: 968px;
    display: flex;
    background-image: url(../../assets/images/dm-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 57px;

    .dataManagementPageHeaderContainer {
      width: 100%;
      max-width: 1560px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .dataManagementPageHeaderTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 85.2427px;
        line-height: 128px;
        color: #ffffff;
        text-align: start;
        max-width: 915px;
      }

      .dataManagementPageHeaderSubtitle {
        max-width: 728px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        text-align: start;
        text-shadow: 0px 4px 17px rgba(0, 0, 0, 0.65);
        margin-bottom: 50px;
      }

      .dataManagementApplyButton {
        padding: 20px 50px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 100px;
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #151515;
        transition: 300ms;
      }

      .dataManagementApplyButton:hover {
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dataManagementTrainingPage {
    .dataManagementPageHeader {
      width: 100%;
      height: 620px;
      align-items: flex-end;
      margin-bottom: 40px;

      .dataManagementPageHeaderContainer {
        max-width: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .dataManagementPageHeaderTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          color: #ffffff;
          margin-bottom: 8px;
        }

        .dataManagementPageHeaderSubtitle {
          max-width: 100%;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 30px;
        }

        .dataManagementApplyButton {
          margin-bottom: 20px;
        }
      }
    }
  }
}
