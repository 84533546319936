@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.applyDMTrainingPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f9f9f9;

  .applyDMTrainingPageHeader {
    width: 100%;
    height: 968px;
    display: flex;
    background-image: url(../../assets/images/apply-sas-trainings-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    .applyDMTrainingPageHeaderBackground {
      display: none;
    }

    .applyDMTrainingPageHeaderContainer {
      width: 100%;
      max-width: 1560px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 70px;

      .applyDMTrainingPageHeaderTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 85.2427px;
        line-height: 128px;
        color: #ffffff;
        text-align: start;
      }

      .applyDMTrainingPageHeaderSubtitle {
        max-width: 726px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        text-align: start;
        text-shadow: 0px 4px 17px rgba(0, 0, 0, 0.65);
        margin-bottom: 50px;
      }

      .applyDMTrainingApplyButton {
        padding: 20px 97px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 100px;
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #151515;
        cursor: pointer;
        transition: 300ms;
      }

      .applyDMTrainingApplyButton:hover {
        background: #2431d8;
        border: 1px solid #ffffff;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .applyDMTrainingPage {
    .applyDMTrainingPageHeader {
      width: 100%;
      height: auto;
      align-items: flex-end;
      background-image: none;

      .applyDMTrainingPageHeaderBackground {
        display: flex;
        background-image: url(../../assets/images/apply-sas-trainings-background.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 100%;
        height: 475px;
        left: 0;
        right: 0;
        top: 0;
      }

      .applyDMTrainingPageHeaderContainer {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        z-index: 1;

        .applyDMTrainingPageHeaderTitle {
          margin-top: 265px;
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 16px;
        }

        .applyDMTrainingPageHeaderSubtitle {
          max-width: 100%;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 42px;
        }

        .applyDMTrainingApplyButton {
          width: 100%;
          max-width: 303px;
          margin-bottom: 0;
          background: #2730ae;
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #ffffff;
        }
      }
    }
  }
}
