@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Montserrat";
  src: url(../../../../assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Ubuntu";
  src: url(../../../../assets/fonts/Ubuntu-Regular.ttf);
}

.joinUsCollapseContainer {
  width: 100%;

  .joinUsCollapse {
    width: 100%;
    background-color: transparent;

    .joinUsCollapsePanel {
      margin-bottom: 20px;

      .ant-collapse-header {
        transition: 2s;
        padding: 25px 30px 25px 15px;
      }
    }

    .joinUsPanelHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .iconContainer {
        width: 160px;
        height: 160px;
        margin-left: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .titleContainer {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .joinUsPanelHeaderTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          text-transform: uppercase;
          color: #001f47;
          text-align: start;
          margin-bottom: 10px;
        }

        .joinUsPanelHeaderLocation {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: start;
          color: #001f47;
        }
      }

      .departmentContainer {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;

        .departmentTitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: right;
          color: #001f47;
          text-align: start;
          margin-left: 10px;
        }

        .departmentContent {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: right;
          color: #001f47;
        }
      }
    }

    .joinUsCollapseContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 40px;

      .collapseContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .jobDataList {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .jobDataListItem {
            text-align: start;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150.4%;
            color: #001f47;
          }
        }

        .aboutJob {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 40px;

          .jobResponsibilities {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-right: 50px;

            .jobResponsibilitiesTitle {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 150.4%;
              color: #001f47;
              margin-bottom: 20px;
            }
          }

          .minimumQualifications {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .minimumQualificationsTitle {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 150.4%;
              color: #001f47;
              margin-bottom: 20px;
            }
          }
        }

        .personalQualities {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 40px;

          .personalQualitiesTitle {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150.4%;
            color: #001f47;
            margin-bottom: 20px;
          }
        }
      }

      .collapseContentFooter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 27px;

        .howToApply {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .howToApplyTitle {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150.4%;
            color: #001f47;
            text-align: start;
          }

          .howToApplyTitleSubtitle {
            max-width: 540px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150.4%;
            color: #001f47;
            text-align: start;
          }
        }

        .applyButton {
          padding: 15px 100px;
          background: #2730ae;
          border: 2px solid #2730ae;
          border-radius: 100px;
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #ffffff;
          word-break: keep-all;
          cursor: pointer;
          transition: 300ms;
        }

        .applyButton:hover {
          background: #5985ef;
          border: 2px solid #ffffff;
          border-radius: 100px;
        }
      }
    }

    .ant-collapse-item {
      background: #dee4f9;
      border-left: 10px solid #6a84f1;
      border-radius: 15px !important;
    }

    .ant-collapse-arrow {
      display: none !important;
    }

    .ant-collapse-content-box {
      background: #dee4f9;
    }
  }
}

@media screen and (max-width: 1024px) {
  .joinUsCollapseContainer {
    width: 100%;

    .joinUsCollapse {
      width: 100%;
      background-color: transparent;

      .joinUsCollapsePanel {
        margin-bottom: 16px;
      }

      .joinUsPanelHeader {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .iconContainer {
          width: 160px;
          height: 160px;
          margin-left: 85px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .titleContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .joinUsPanelHeaderTitle {
            font-family: "PoppinsBold";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            text-transform: uppercase;
            color: #001f47;
            text-align: start;
            margin-bottom: 8px;
          }

          .joinUsPanelHeaderLocation {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #001f47;
            margin-bottom: 15px;
          }
        }

        .departmentContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .departmentTitle {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-align: start;
            color: #001f47;
            margin-left: 0;
            margin-bottom: 15px;
          }

          .departmentContent {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-align: start;
            color: #001f47;
          }
        }
      }

      .joinUsCollapseContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 25px 25px 25px 15px;

        .collapseContent {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .jobDataList {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-left: 10px;

            .jobDataListItem {
              text-align: start;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 150.4%;
              color: #001f47;
            }
          }

          .aboutJob {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 40px;

            .jobResponsibilities {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-right: 0;

              .jobResponsibilitiesTitle {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 150.4%;
                color: #001f47;
                margin-bottom: 20px;
                text-align: start;
              }
            }

            .minimumQualifications {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              .minimumQualificationsTitle {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 150.4%;
                color: #001f47;
                margin-top: 20px;
                margin-bottom: 20px;
              }
            }
          }

          .personalQualities {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 10px;

            .personalQualitiesTitle {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 150.4%;
              color: #001f47;
              margin-top: 20px;
              margin-bottom: 20px;
            }
          }
        }

        .collapseContentFooter {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 5px;

          .howToApply {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .howToApplyTitle {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 150.4%;
              color: #001f47;
              text-align: start;
            }

            .howToApplyTitleSubtitle {
              max-width: 540px;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 150.4%;
              color: #001f47;
              text-align: start;
            }
          }

          .applyButton {
            padding: 15px 100px;
            background: #2730ae;
            border-radius: 100px;
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: #ffffff;
            word-break: keep-all;
            cursor: pointer;
            margin-top: 35px;
          }
        }
      }

      .ant-collapse-item {
        background: #dee4f9;
        border-left: 10px solid #6a84f1;
        border-radius: 15px !important;
      }

      .ant-collapse-arrow {
        display: none !important;
      }

      .ant-collapse-content-box {
        background: #dee4f9;
      }
    }
  }
}
