@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}


.trainingsPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;

  .trainingsPageHeaderBackground {
    position: absolute;
    top: -150px;
    bottom: -150px;
    left: -150px;
    right: -150px;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba(11, 21, 57, 0.74) 9.58%,
      rgba(38, 69, 180, 0) 97.69%
    );
    mix-blend-mode: normal;
    filter: blur(19px);
  }

  .trainingsPageHeader {
    width: 100%;
    height: 887px;
    display: flex;
    background-image: url(../../assets/images/trainings-page-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .trainingsPageHeaderContainer {
      width: 100%;
      max-width: 1560px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: hidden;
      z-index: 1;

      .trainingsPageHeaderTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 64px;
        line-height: 96px;
        color: #ffffff;
        margin-bottom: 20px;
      }

      .trainingsPageHeaderSubtitle {
        max-width: 578px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.65);
        text-align: start;
      }

      .trainingsPageHeaderApplyButton {
        background: #ffffff;
        border: 1px solid #151515;
        border-radius: 100px;
        padding: 20px 50px;
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #151515;
        margin-top: 50px;
        transition: 300ms;
      }

      .trainingsPageHeaderApplyButton:hover {
        background: #ffffff;
        border: 1px solid #151515;
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .trainingsPage {
    width: 100%;

    .trainingsPageHeader {
      height: 475px;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      overflow: hidden;

      .trainingsPageHeaderContainer {
        max-width: 100%;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden;
        z-index: 1;

        .trainingsPageHeaderTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 20px;
        }

        .trainingsPageHeaderSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 16px;
        }

        .trainingsPageHeaderApplyButton {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #151515;
          margin-bottom: 20px;
          margin-top: 0;
        }
      }
    }
  }
}
