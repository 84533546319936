@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;

  .headerItem {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin-left: 57px;
    cursor: pointer;
  }

  .headerItemDark {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-left: 57px;
    cursor: pointer;
  }

  .icon {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    line-height: 10px;
  }

  .iconDark {
    color: #000000;
    font-size: 22px;
    line-height: 10px;
    font-weight: 400;
  }
}

.headerItemDark {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-left: 57px;
  cursor: pointer;
}

.ant-dropdown {
  .ant-dropdown-menu {
    position: relative;
    margin: 15px 0 0 80px;
    padding: 0;
    text-align: left;
    list-style-type: none;
    background: transparent;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);

    .ant-dropdown-menu-item {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      padding: 15px 20px 15px 15px;
      background: rgba(21, 21, 21, 0.4);
    }

    .ant-dropdown-menu-item:hover {
      background: rgba(21, 21, 21, 0.5);
    }
  }
}

@media screen and (max-width: 1450px) {
  .headerItem {
    margin-left: 40px;
  }

  .headerItemDark {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1400px) {
  .headerItem {
    margin-left: 30px;
  }

  .headerItemDark {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .ant-dropdown {
    .ant-dropdown-menu {
      position: relative;
      margin: 0;
      padding: 0;
      text-align: left;
      list-style-type: none;
      background: transparent;
      background-clip: padding-box;
      border-radius: 2px;
      outline: none;
      box-shadow: none;

      .ant-dropdown-menu-item {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        padding: 0;
        background: transparent;
      }

      .ant-dropdown-menu-item:hover {
        background: rgba(21, 21, 21, 0.5);
      }
    }
  }
}
