@font-face {
  font-family: "Poppins";
  src: url(../../../../../assets/fonts/Poppins-Regular.ttf);
}

.ourValuesCard {
  width: 100%;
  max-width: 25%;
  margin-right: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #2730ae;
  height: 380px;
  padding: 40px 37px;
  transition: 300ms;

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 25px;
    max-height: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}

.ourValuesCard:hover {
  box-shadow: (-8px 7px 30px rgba(6, 10, 23, 0.35));
}

@media screen and (max-width: 1024px) {
  .ourValuesCard {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
    height: 320px;
    padding: 20px;

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 34px;
    }

    .subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }
  }
}
