@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.ourMissionSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ourMissionContainer {
    width: 52%;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;

    .ourMissionContent {
      width: 100%;
      max-width: 798px;
      padding: 35px 0 35px 56px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .ourMissionTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 152.9%;
        color: #151515;
        margin-bottom: 20px;
        text-align: start;
      }

      .ourMissionSubtitle {
        width: 100%;
        max-width: 655px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #151515;
        text-align: start;
        margin-bottom: 18px;
      }

      .seeMoreButtonContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .seeMoreButton {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: baseline;
          cursor: pointer;

          .seeMoreButtonContent {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #151515;
            transition: 300ms;
          }

          .icon {
            width: 100%;
          }
        }

        .seeMoreButton:hover > .seeMoreButtonContent {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 30px;
          color: #151515;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ourMissionSection {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ourMissionContainer {
      width: 100%;
      background: #ffffff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 0;

      .ourMissionContent {
        width: 100%;
        max-width: 798px;
        padding: 20px;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .ourMissionTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 152.9%;
          color: #151515;
          margin-bottom: 10px;
          text-align: start;
        }

        .ourMissionSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #151515;
          text-align: start;
          margin-bottom: 20px;
        }

        .seeMoreButtonContainer {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .seeMoreButton {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: baseline;
            cursor: pointer;

            .seeMoreButtonContent {
              font-family: "PoppinsBold";
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 30px;
              color: #151515;
            }

            .icon {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
