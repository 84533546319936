.statisticsSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edf1ff;
  margin-top: 60px;
  padding: 67px 0 62px 0;

  .statisticsSectionContainer {
    width: 100%;
    max-width: 1368px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .statisticsSectionContainer > .statisticsSectionCard:nth-last-child(1) {
    border-right: none;
  }
}

@media screen and (max-width: 1024px) {
  .statisticsSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #edf1ff;
    margin-top: 50px;
    padding: 20px 0;

    .statisticsSectionContainer {
      width: 100%;
      max-width: 1368px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .statisticsSectionContainer > .statisticsSectionCard:nth-last-child(1) {
      border-right: none;
      border-bottom: none;
    }
  }
}
