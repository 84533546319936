.layout {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .contentContainer {
      width: 100%;
      max-width: 1560px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 0 20px;
    }
  }
}
