@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.joinUsPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .joinUsPageHeader {
    width: 100%;
    height: 542px;
    display: flex;
    background-image: url(../../assets/images/join-us-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .joinUsPageHeaderContainer {
      width: 100%;
      max-width: 1220px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .joinUsPageHeaderTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 64.3732px;
        line-height: 97px;
        color: #ffffff;
        margin-bottom: 20px;
      }

      .joinUsPageHeaderSubtitle {
        max-width: 500px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        text-align: start;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .joinUsPage {
    justify-content: center;

    .joinUsPageHeader {
      height: 375px;
      display: flex;
      background-image: url(../../assets/images/join-us-background.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .joinUsPageHeaderContainer {
        width: 100%;
        max-width: 100%;
        padding: 0 20px 30px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .joinUsPageHeaderTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 16px;
        }

        .joinUsPageHeaderSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
}
