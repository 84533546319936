@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.servicesPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f6f8ff;

  .servicesPageHeader {
    width: 100%;
    height: 900px;
    display: flex;
    background-image: url(../../assets/images/services-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .holla {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(38, 69, 180, 0.4);
      mix-blend-mode: multiply;
      opacity: 0.9;
      backdrop-filter: blur(6px);
    }

    .servicesPageHeaderContainer {
      width: 100%;
      max-width: 1220px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      z-index: 1;

      .servicesPageHeaderTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 85.2427px;
        line-height: 80px;
        color: #ffffff;
        margin-bottom: 30px;
        text-align: start;
      }

      .servicesPageHeaderSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 130%;
        color: #ffffff;
        max-width: 595px;
        text-align: start;

        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 130%;
          color: #ffffff;
          max-width: 595px;
          text-align: start;
          display: inline;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .servicesPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f6f8ff;

    .servicesPageHeader {
      width: 100%;
      height: 375px;
      justify-content: center;
      align-items: flex-end;

      .holla {
        background: rgba(38, 69, 180, 0.4);
        mix-blend-mode: multiply;
        opacity: 0.9;
        backdrop-filter: blur(1.17188px);
      }

      .servicesPageHeaderContainer {
        max-width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .servicesPageHeaderTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 130%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 30px;
          text-align: center;
          margin-bottom: 10px;
        }

        .servicesPageHeaderSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 120%;
          text-align: center;
          color: #ffffff;
          margin-bottom: 20px;

          h1 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #ffffff;
            margin-bottom: 20px;
            display: inline;
          }
        }
      }
    }
  }
}
